<template>
  <div class="home" >
    
    <div v-show="$store.state.scrollState.widthSB > $store.state.scrollWidthFixed.small.symbol" class="bgTest" style="background-color: lightgray;">
      <!-- :style="{background:screenWidth>768?background1:background2}" -->
      <div style="background-color: white;padding:30px;" class="center-vertical center-horizontal pop" v-show="lang=='en'">
          <h3>BOUTIQUES</h3><br><br>
          <p>Find your nearest <span style="color:rgb(61,34,26)"><br><img style="height:20px;line-height: 30px;" src="@/static/images/logo.png"/></span> boutique</p><br>
          <a style="text-decoration: underline;" id="discoverBtn" @click="goodsListClick(17)">&nbsp;Discover&nbsp;</a><br><br>
      </div>
      <div style="background-color: white;padding:30px;" class="center-vertical center-horizontal pop" v-show="lang=='zh'">
          <h3>体验店</h3><br><br>
          <p>寻找您附近的 <span style="color:rgb(61,34,26)"><br><img style="height:20px;line-height: 30px;" src="@/static/images/logo.png"/></span> 体验店</p><br>
          <a style="text-decoration: underline;" id="discoverBtn" @click="goodsListClick(17)">&nbsp;发现&nbsp;</a><br><br>
      </div>
    </div>


    <div v-show="$store.state.scrollState.widthSB <= $store.state.scrollWidthFixed.medium.symbol" class="bgTestMini" style="background-color: lightgray;">
      <!-- :style="{background:screenWidth>768?background1:background2}" -->
      <div style="background-color: white;padding:30px;" class="center-vertical center-horizontal pop" v-show="lang=='en'">
          <h3>BOUTIQUES</h3><br><br>
          <p>Find your nearest <span style="color:rgb(61,34,26)"><br><img style="height:20px;line-height: 30px;" src="@/static/images/logo.png"/></span> boutique</p><br>
          <a style="text-decoration: underline;" id="discoverBtn" @click="goodsListClick(17)">&nbsp;Discover&nbsp;</a><br><br>
      </div>
      <div style="background-color: white;padding:30px;" class="center-vertical center-horizontal pop" v-show="lang=='zh'">
          <h3>体验店</h3><br><br>
          <p>寻找您附近的 <span style="color:rgb(61,34,26)"><br><img style="height:20px;line-height: 30px;" src="@/static/images/logo.png"/></span> 体验店</p><br>
          <a style="text-decoration: underline;" id="discoverBtn" @click="goodsListClick(17)">&nbsp;发现&nbsp;</a><br><br>
      </div>

    </div>
      <br>

  </div>
</template>
 
<script>
export default {
  data(){
    return{
    }
  },
  components: {
  },
  computed:{
    screenWidth(){
            return this.$store.state.scrollState.width;
        },
        lang() {//语言
            return localStorage.getItem('language');
        }

  },
  methods:{
    goodsListClick(typeId) {
            console.log("当前:" + this.$route.path);
                //跳转
                this.$router.push({
                    name: 'goodsList',
                    query: {
                        'typeId': typeId,    //跳路由
                    }
                });

                //EventBus.$emit("IndexNavbarMsgGoodsList", typeId);
            
        },
  }
};
</script>
<style>
.bgTest {
  height: 90vh;
  background: url('@/static/images/home_shicha1.jpg');
  background-repeat:  no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  font-size: 20px;
  overflow: hidden;
}

.bgTestMini {
  height: 90vh;
  background: url('@/static/images/home_shicha1_mini.jpg');
  background-repeat:  no-repeat;
  background-position: cover;
  background-size: cover;
  font-size: 20px;
  overflow: hidden;
}

.center-vertical {
    position: relative;
    top: 30%;
    transform: translateY(-50%);
}

.center-horizontal {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

#discoverBtn:hover{
    font-weight: 600;
    text-decoration: none !important;
    cursor: pointer;
}

/* 小屏 */
@media screen and (max-width: 768px) {
   .pop{
      width:70%;
   }
  
}


/* 大屏 */
@media screen and (min-width: 768px) {
  .pop{
    width:30%;
   }

}


</style>