import store from '@/store'

//屏幕宽度变化时,则触发该方法
//屏幕宽度,和屏幕宽度标识,存储到vuex中
export function screenWidthStoreToVuex(screenWidth) {
    //屏幕宽度,存储到vuex中
    store.commit('changeScrollStateWidth', screenWidth);
    //屏幕宽度标识,存储到vuex中
    if (screenWidth < store.state.scrollWidthFixed.extraSmall.max
        && screenWidth >= store.state.scrollWidthFixed.extraSmall.min
    ) {
        store.commit('changeScrollStateWidthSB', store.state.scrollWidthFixed.extraSmall.symbol);
    }
    else if (screenWidth < store.state.scrollWidthFixed.small.max
        && screenWidth >= store.state.scrollWidthFixed.small.min
    ) {
        store.commit('changeScrollStateWidthSB', store.state.scrollWidthFixed.small.symbol);
    }
    else if (screenWidth < store.state.scrollWidthFixed.medium.max
        && screenWidth >= store.state.scrollWidthFixed.medium.min
    ) {
        store.commit('changeScrollStateWidthSB', store.state.scrollWidthFixed.medium.symbol);
    }
    else if (screenWidth < store.state.scrollWidthFixed.large.max
        && screenWidth >= store.state.scrollWidthFixed.large.min
    ) {
        store.commit('changeScrollStateWidthSB', store.state.scrollWidthFixed.large.symbol);
    }
    else if (screenWidth < store.state.scrollWidthFixed.xLarge.max
        && screenWidth >= store.state.scrollWidthFixed.xLarge.min
    ) {
        store.commit('changeScrollStateWidthSB', store.state.scrollWidthFixed.xLarge.symbol);
    }
    else if (screenWidth < store.state.scrollWidthFixed.xXLarge.max
        && screenWidth >= store.state.scrollWidthFixed.xXLarge.min
    ) {
        store.commit('changeScrollStateWidthSB', store.state.scrollWidthFixed.xXLarge.symbol);
    } else {
        store.commit('changeScrollStateWidthSB', store.state.scrollWidthFixed.unknown.symbol);
    }
}
//监听屏幕宽度变化
export function screenWidthListener() {
    console.log("监听屏幕宽度变化");
    console.log(document.body.clientWidth);
    //初始化,屏幕宽度,存储到vuex中
    screenWidthStoreToVuex(document.body.clientWidth);
    window.onresize = () => {   //屏幕尺寸变化就重新赋值
        return (() => {
            screenWidthStoreToVuex(document.body.clientWidth);
        })()
    }
}
//监听屏幕滚动
export function scrollingCheckListener(){
    console.log("屏幕滚动,默认是0");
    store.commit('changeScrollStateScrolling', 0);
    window.addEventListener("scroll", scrollingCheck);
}

//监听页面滚动时,则触发当前方法
export function scrollingCheck() {
    // 滚动条距文档顶部的距离
    let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
    // 滚动条滚动的距离
    let scrollStep = scrollTop - this.oldScrollTop;
    //console.log("header 滚动距离 ", scrollTop);
    // 更新——滚动前，滚动条距文档顶部的距离
    this.oldScrollTop = scrollTop;


    //屏幕滚动信息,存储到vuex中
    store.commit('changeScrollStateScrolling', scrollTop);

    //变量windowHeight是可视区的高度
    let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
    //变量scrollHeight是滚动条的总高度
    let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;

    //滚动条到底部的条件
    if (scrollTop + windowHeight == scrollHeight) {
        //你想做的事情
        //  console.log("header  你已经到底部了");
    }
    if (scrollStep < 0) {
        // console.log("header 滚动条向上滚动了！");
    } else {
        //  console.log("header  滚动条向下滚动了！");
    }
    // 判断是否到了最顶部
    // if (scrollTop <= 0) {
    //  // console.log("header 到了最顶部")
    //   this.backgroundColor = 'rgba(0,0,0,0) !important';
    // }else{
    //   this.backgroundColor = 'rgba(255,255,255,1) !important';
    // }

}
