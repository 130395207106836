<template>
  <div>
    <nav class="navbar navbar-expand-lg bg-body-tertiary outerClass" style="z-index:99;height:60px !important;"
      :style="[{ background: menuCursor ? backgroundColor2 : backgroundColor1 }]" @mouseenter="mouseenter()"
      @mouseleave="mouseleave()">
      <!-- <div style="background-color: red;width:100% !important;">hahah1</div> -->
      <ul style="width:100% !important;height:100%;list-style: none;position: relative;">
        <li :v-show="scrolling == 0"
          style="background-color: black;width:100% !important;background:cover;position: absolute;left: 0;height:20px;">
          <div
            style="background-color: black;width:100wv !important;background:cover;color:white;font-family: Jungka,sans-serif;font-size: 12px;padding:6px 0;font-weight: 600;">
            THE BEST OF THE BEST +1
          </div>
        </li>
        <li style="width:100% !important;height:60px;line-height: 30px;position: absolute;left: 0;">
          <nav class="fixed-top navbar navbar-expand-lg bg-body-tertiary outerClass"
            style="z-index:99;border-bottom: 1px solid rgba(255,255,255,0.3);;" :style="[{ background: menuCursor ? backgroundColor2 : backgroundColor1 },
            { paddingTop: menuCursor ? '10px' : '10px' },
              , { height: menuCursor ? '60px' : '60px' }
              , { marginTop: scrolling == 0 ? '30px' : '0px' }
            ]">
            <div style="width:45%;text-align: center;position: relative;color:red !important;">
              <!-- <button type="button" class="btn btn-link">链接按钮</button> -->
              <!--  -->
              <ul class="nav nav-pills" style="position: absolute;left:0;top:-20px;">
                <li class="nav-item dropdown" ref="dropDownMenuMan" v-show="menuData.MAN.length>0">
                  <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button"
                    aria-expanded="false" :class="[menuCursor ? 'colorBlack' : 'colorWhite']">{{ $t('nanshi') }}</a>
                  <ul class="dropdown-menu" id="dropdown-menu-man">
                    <!-- 男士菜单 -->
                    <li><a class="dropdown-item" v-for="(item, index) in menuData.MAN" @click="goodsListClick(item.id)">{{
                      language == 'en' ? item.label_en : item.label_zh }}</a></li>

                    <!-- <li>
                      <hr class="dropdown-divider">
                    </li>
                    <li><a class="dropdown-item" href="#">Separated link</a></li> -->
                  </ul>
                </li>
                <li class="nav-item dropdown" v-show="menuData.WOMAN.length>0">
                  <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button"
                    aria-expanded="false" :class="[menuCursor ? 'colorBlack' : 'colorWhite']">{{ $t('nvshi') }}</a>
                  <ul class="dropdown-menu">
                     <!-- 女士菜单 -->
                    <li><a class="dropdown-item" v-for="(item, index) in menuData.WOMAN"
                        @click="goodsListClick(item.id)">{{ language == 'en' ? item.label_en : item.label_zh }}</a></li>

                    <!-- <li>
                      <hr class="dropdown-divider">
                    </li>
                    <li><a class="dropdown-item" href="#">Separated link</a></li> -->
                  </ul>
                </li>


                
                <!-- <li class="nav-item">
                  <a class="nav-link" href="#" :class="[menuCursor ? 'colorBlack' : 'colorWhite']">{{ $t('name') }}</a>
                </li> -->
                <!-- <li class="nav-item">
                  <a class="nav-link" :class="[menuCursor ? 'colorBlack' : 'colorWhite']" @click="changePage('/')">{{
                    $t('shouye') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="[menuCursor ? 'colorBlack' : 'colorWhite']"
                    @click="changePage('/goodsList')">{{ $t('shangpinliebiao') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="[menuCursor ? 'colorBlack' : 'colorWhite']"
                    @click="changePage('/test')">测试</a>
                </li> -->
                <!-- <li class="nav-item">
                  <a class="nav-link" href="#" :class="[menuCursor ? 'colorBlack' : 'colorWhite']">Link</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#" :class="[menuCursor ? 'colorBlack' : 'colorWhite']">Link</a>
                </li> -->
              </ul>
              <!--  -->
            </div>
            <div style="width:10%;">
              <img src="@/static/images/logo2.png" style="height:25px;" @click="changePage('/')" class="logo">
            </div>
            <div style="width:45%;position: relative;">
              <ul class="nav nav-pills" style="position: absolute;right:0;top:-20px;">
                <!-- <li class="nav-item">
                  <a class="nav-link" href="#" :class="[menuCursor ? 'colorBlack' : 'colorWhite']">Link</a>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button"
                    aria-expanded="false" :class="[menuCursor ? 'colorBlack' : 'colorWhite']">Dropdown</a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#">Action</a></li>
                    <li><a class="dropdown-item" href="#">Another action</a></li>
                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                    <li>
                      <hr class="dropdown-divider">
                    </li>
                    <li><a class="dropdown-item" href="#">Separated link</a></li>
                  </ul>
                </li> -->
                <!-- <li class="nav-item">
                  <a class="nav-link" href="#" :class="[menuCursor ? 'colorBlack' : 'colorWhite']">{{ $t('guanyu') }}</a>
                </li> -->
                <li class="nav-item">

                </li>
                <li class="nav-item">
                  <a data-bs-toggle="offcanvas" data-bs-target="#cartNavbar" class="nav-link" aria-controls="cartNavbar"
                    aria-label="Toggle navigation" :class="[menuCursor ? 'colorBlack' : 'colorWhite']">{{ $t('gouwuche')
                    }}</a>
                </li>
                <li class="nav-item" style="margin-left:50px;">
                  <i class="icon icon-font" @click="setLanguage()"
                    style="border:none;padding:30px; font-size: 30px;position: absolute;top:-22px;right:20px;padding-left:0;"
                    :class="[menuCursor ? 'colorBlack' : 'colorWhite', language === 'zh' ? 'icon-yingwen1' : 'icon-zhongwen1']"></i>
                </li>
              </ul>
            </div>
          </nav>
        </li>
      </ul>
    </nav>
    <!-- <nav class="fixed-top navbar navbar-expand-lg bg-body-tertiary outerClass" style="z-index:99;height:80px !important;"
      :style="{ background: scrolling > 0 ? backgroundColor2 : backgroundColor1 }">
        <div style="width:40%;text-align: center;">
          <button type="button" class="btn btn-link">链接按钮</button>
          <button type="button" class="btn btn-link">链接按钮</button>
          <button type="button" class="btn btn-link">链接按钮</button>
          <button type="button" class="btn btn-link">链接按钮</button>
        </div>
        <div style="width:20%;">
111
        </div>
        <div style="width:40%;">
111
        </div>
    </nav> -->
    <!-- 购物车列表 -->
    <div style="width:20%;" class="offcanvas offcanvas-end" tabindex="-1" id="cartNavbar"
      aria-labelledby="offcanvasNavbarLabel">
      <div class="offcanvas-header">
        <!-- <h5 class="offcanvas-title" id="offcanvasNavbarLabel">AruGaw</h5> -->
        <span>{{ $t('gouwuche') }}</span>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
      </div>
    </div>
    <!-- 购物车列表 -->
  </div>
</template>
<script>

import { EventBus } from "../event-bus.js"

import { gsap } from "gsap";
import { changeLanguage } from '../static/js/main/language'
import router from "@/router";

export default {
  data() {
    return {
      //底色根据滚动位置而动态变化
      backgroundColor1: 'rgba(0,0,0,0) !important',
      backgroundColor2: 'rgba(255,255,255,1) !important',
      color2: 'white !important',
      color1: 'black !important',
      currentRoute: null,


      menuCursor: false,//鼠标移入菜单,标识

      language: '',
      //language:'',

      whiteBgList: ["/goodsView"],//包含的页面直接白色底,不做效果切换
      menuData: {
        "MAN": [
        ],
        "WOMAN": [
        ]
      },//菜单数据
      typeIdHis: -1,//typeid历史


      dropDownMenuManOpen: false,//
    }
  },
  mounted() {
    //初始化国家化
    this.initLanguage();
    console.log("iii" + this.language);
    this.currentRoute = this.$route.path
    this.$watch('$route', (to, from) => {
      this.currentRoute = to.path
    })

    this.logoAnimation();

    //初始化菜单数据
    this.getMenu();

    //dropdown-menu-man


  },
  methods: {
    //点击导航,触发的页面跳转
    changePage(path) {
      //获取当前导航
      console.log("当前:" + this.$route.path);
      if (this.$route.path != path) {
        //跳转
        router.push(path);
      }
    },
    //点击商品,页面跳转
    goodsListClick(typeId) {
      console.log("当前:" + this.$route.path);
      if (this.$route.path != "/goodsList") {
        
        this.typeIdHis = -1;
        //跳转
        this.$router.push({
          name: 'goodsList',
          query: {
            'typeId': typeId,    //跳路由
          }
        });
        //EventBus.$emit("IndexNavbarMsgGoodsList", typeId);
      } else {
        //通知goodsList页面,当前用户更换了typeId
        if (this.typeIdHis != typeId) {
          this.typeIdHis = typeId;//记录typeId历史
          EventBus.$emit("IndexNavbarMsgGoodsList", typeId);
          this.$router.push({
            name: 'goodsList',
            query: {
              'typeId': typeId,    //跳路由
            }
          });
        }



      }
    },
    //初始化国家化
    initLanguage() {
      if (localStorage.getItem('language') == "" || localStorage.getItem('language') == null) {
        localStorage.setItem("language", "en");
        location.reload();//刷新页面,使得语言生效
      }
      console.log("初始化语言:" + localStorage.getItem('language'))
      this.$i18n.locale = localStorage.getItem('language');
      this.language = this.$i18n.locale;
      console.log("初始化language:" + this.language);
    },
    //国家化,切换语言
    setLanguage() {
      // 切换语言
      this.language = this.$i18n.locale = this.language === 'zh' ? 'en' : 'zh';
      localStorage.setItem("language", this.language);

      console.log("切换语言:" + localStorage.getItem('language'))
      console.log("切换language:" + this.language);
      this.$router.go(0);
    },
    logoAnimation() {

      //logo动画
      var that = this;
      gsap.from(".logo", {
        //background:'white',
        filter: 'invert(100%)',
        duration: 4,
        repeat: 100,
        ease: 'power2.inOut', // 关键帧整体的动画曲线设置,平滑
        //delay:3,
        onStart() {
          console.log("logo");
        }
      })

    },
    toPage(path) {
      if (this.currentRoute != path) {
        //页面跳转
        this.$router.push(path);
      }

    },
    mouseenter() {
      this.menuCursor = true;//鼠标移入
      console.log("enter");
    },
    mouseleave() {

      if (this.scrolling == 0) {
        this.menuCursor = false;//鼠标移出
        console.log("leave");
      }

    },
    getMenu() {//获取菜单//this.$store.state.requestUrl.getMenuList
      var that = this;
      that.$axios.get(that.$store.state.requestUrl.getMenuList)
        .then(function (response) {
          console.log("response:" + JSON.stringify(response.data.data));
          that.menuData = response.data.data;
          console.log("JSON.parse(response.data):::::" + response.data.data);
          //存储Menu信息
          that.$store.commit('changeMenuInfo', response.data.data);
        })
        .catch(function (error) {
        });

    }
  },
  computed: {
    //y滚动的位置
    scrolling() {

      if (this.whiteBgList.includes(this.currentRoute)) {
        //直接白色底
        console.log("直接白色底");
        return "1";
      } else {
        return this.$store.state.scrollState.scrolling;
      }

    },
    title() {
      return this.$store.state.scrollState.scrolling;
    },
  },
  watch: {
    scrolling: {
      handler(curVal, newVal) {
        if (curVal > 0) {
          this.menuCursor = true;
        } else {
          this.menuCursor = false;
        }
      }
    }
  }
}
</script>
<style>
.navbar-collapse a:hover {
  cursor: pointer;
}

.colorBlack {
  color: black !important;
}

.colorWhite {
  color: rgba(255, 255, 255, 0.7) !important;
}

.nav-item:hover {
  cursor: pointer;
}
</style>