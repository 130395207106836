<template>
  <div ref="topDiv">

    
    <HomeCarsouel></HomeCarsouel>

    <!-- margin-top:100vh是为了撑开上面轮播图的位置,必须存在 -->
    <HomeContent  style="margin-top:100vh"></HomeContent>
    <!-- <div class="container">
      <div class="jumbotron">
        <div></div>
        <h1>欢迎登陆页面！111</h1>
        <p>这是一个超大屏幕（Jumbotron）的实例。</p>
        <p><a class="btn btn-primary btn-lg" role="button">
            学习更多</a>
        </p>
      </div>
    </div> -->
    <HomeShicha></HomeShicha>
    <!--  -->
    <!-- <HomeShopInformation></HomeShopInformation> -->
    <Footer></Footer>

    <!-- <div class="container">
      <div class="jumbotron">
        <div></div>
        <h1>欢迎登陆页面！111</h1>
        <p>这是一个超大屏幕（Jumbotron）的实例。</p>
        <p><a class="btn btn-primary btn-lg" role="button">
            学习更多</a>
        </p>
      </div>
    </div> -->
    <!--  -->
    

    <!--  -->
  </div>
</template>
<script>
// @ is an alias to /src
import HomeCarsouel from '@/components/home/Home-Carsouel.vue'
import HomeShicha from '@/components/home/Home-Shicha.vue'
import HomeContent from '@/components/home/Home-Content.vue'
import Footer from '@/components/Footer.vue'
import HomeShopInformation from '@/components/home/Home-ShopInformation.vue'
export default {
  name: 'HomeView',
  components: {
    HomeCarsouel,
    HomeShicha,
    HomeContent,
    Footer,
    HomeShopInformation,
  },
  mounted(){
    console.log("顶部");
    this.handleScrollTop();
    document.documentElement.scrollTop = 0;
  },
  methods:{
    handleScrollTop() {
      this.$nextTick(() => {
        let scrollElem = this.$refs.topDiv;
        scrollElem.scrollTo({ top: 0, behavior: 'smooth' });
      });
    },
  }
}
</script>
