const zh = {
    nvshi:"女士",
    nanshi:"男士",
    name: '测试',
    shouye:'首页',
    gouwuche:'购物车',
    guanyu:'关于',
    shangpinliebiao:'商品列表',

    goodslist_paixu_title:"排序",
    goodslist_paixu_sale_count:"销量",//标识 saleCount
    goodslist_paixu_time:"最新上架",//标识 time

    goodslist_shouye:"首页",
    goodslist_weiye:"尾页",
    goodslist_xiayiye:"下一页",
    goodslist_shangyiye:"上一页",

    goodslist_products:"件商品",

    goods_jiarugouwuche:"加  入  购  物  车"

  }
  export default zh