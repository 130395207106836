<template>
    <div>
        <div class="row">
            <!-- 内容第一行 元素 开始 -->
            <div style="position: relative;overflow: hidden;z-index:0;padding:100rpx;width:100%;">
                <img class="col-md-12 clickBtn1Image" @mouseover="clickBtn1MouseOver()" @mouseout="clickBtn1MouseOut()"
                    style="height:60vh;width:100vw;object-fit:cover;object-position: bottom;z-index: -1;"
                    :src="$store.state.scrollState.widthSB > $store.state.scrollWidthFixed.medium.symbol ? 'https://gw-mall-bucket.oss-cn-beijing.aliyuncs.com/static/home/home-man-pc.jpg' : 'http://gw-mall-bucket.oss-cn-beijing.aliyuncs.com/static/home/home_man_mobile.jpg'" />
                <div style="position: absolute;top:30vh;width:100%;">
                    <h2 style="color:white;font-weight: 800;">{{lang=="en"? "Men`s Collection":"男士系列"}}</h2>
                    <h3 style="margin-top: 40px;">
                        <!-- VIEW PRODUCTS -->
                        <div>
                            <button type="button" class="btn btn-light clickBtn1 btn-lg btnSingle"
                                @mouseover="clickBtn1MouseOver()"
                                style="font-family: Jungka,sans-serif;border-radius:29px !important;font-size: 12px;padding:20px;white-space: nowrap;width: fit-content;min-width: 20%;"
                                @mouseout="clickBtn1MouseOut()" @click="goodsListClick(17)">{{lang=="en"? "VIEW PRODUCTS":"查看商品"}}</button>
                        </div>
                    </h3>
                </div>
            </div>
            <!-- 内容第二行 元素 开始
            <div style="position: relative;overflow: hidden;z-index:0;margin-top:30px;">
                <img class="col-md-12 clickBtn2Image" @mouseover="clickBtn2MouseOver()" @mouseout="clickBtn2MouseOut()"
                    style="height:60vh;width:100vw;object-fit:cover;object-position: bottom;z-index: -1;"
                    :src="$store.state.scrollState.widthSB > $store.state.scrollWidthFixed.medium.symbol ? 'https://gw-mall-bucket.oss-cn-beijing.aliyuncs.com/static/home/home-woman-pc.jpg' : 'http://gw-mall-bucket.oss-cn-beijing.aliyuncs.com/static/home/home_woman_mobile.jpg'" />
                <div style="position: absolute;top:30vh;width:100%;">
                    <h2 style="color:white;font-weight: 800;">Woman`s Collection</h2>
                    <h3 style="margin-top: 40px;">
                         VIEW PRODUCTS
                        <div>
                             @mouseout="clickBtn2MouseOut()" @mouseover="clickBtn2MouseOver()"
                            <button type="button" class="btn btn-light clickBtn2 btn-lg btnSingle" @click="goodsListClick(18)"
                                style="font-family: Jungka,sans-serif;border-radius:29px !important;font-size: 12px;padding:20px;white-space: nowrap;width: fit-content;min-width: 20%;">VIEW
                                PRODUCTS</button>
                        </div>
                    </h3>
                </div>
            </div> -->
        </div>
        <br>
        <div class="row descTextFa1" style="background-color: rgb(218,217,214);">
            <div class="col-xs-12 col-sm-6 fourImg descText descText1"
                style="background-color: rgb(218,217,214);color:black;">
                <h3>{{lang=="en"?"Suit —— The silhouette of fashion":"礼服  时尚的剪影 "}}</h3><br>
                <p>
                    {{ lang=="en"?"Wool and silk are with elegance structural pattern which is different style, so the essence of suits will be redefined.":"羊毛+真丝的面料，配上精美的结构性提花诠释不一样的风味，礼服的精髓又被重新定义。" }}
                </p>
                <p style="text-decoration: underline;" class="discoverBtn">{{ lang=="en"?"Discover more":"发现更多" }}</p>
            </div>
            <img class="col-xs-12 col-sm-6 fourImg fourImg1"
                style="background-color: #rgb(218,217,214);height:50vh;object-fit: cover;object-position: center;overflow: hidden;padding:0;"
                src="../../static/images/home1.jpg" />
        </div>
        <div class="row descTextFa2" style="background-color: rgb(6,51,145);">
            <div class="col-xs-12 col-sm-6 csminshow fourImg descText descText2"
                style="background-color: rgb(6,51,145);color:white;">
                <h3>{{ lang=="en"?"New structure suit":"新结构西装" }}</h3><br>
                <p v-show="lang=='en'">
                     Structural innovation  <br> Mix new clothing language <br> Using shape and volume to express our new fashion attitude.
                </p>
                <p v-show="lang=='zh'">  结构的更迭  <br>混合新的服装语言  <br>用形状和体积来表达我们新的服装态度</p>
                
                <p style="text-decoration: underline;" class="discoverBtn"  @click="goodsListClick(17)">{{ lang=="en"?"Discover more":"发现更多" }}</p>
            </div>
            <img class="col-xs-12 col-sm-6 fourImg fourImg2"
                style="padding:0;background-color: rgb(6,51,145);height:50vh;object-fit: cover;object-position: center;overflow: hidden;"
                src="../../static/images/home2.jpg" />
            <div class="col-xs-12 col-sm-6 csmaxshow fourImg descText descText2"
                style="background-color: rgb(6,51,145);color:white;;">
                <h3>{{ lang=="en"?"New structure suit":"新结构西装" }}</h3><br>
                <p v-show="lang=='en'">
                     Structural innovation  <br> Mix new clothing language <br> Using shape and volume to express our new fashion attitude.
                </p>
                <p v-show="lang=='zh'">  结构的更迭  <br>混合新的服装语言  <br>用形状和体积来表达我们新的服装态度</p>
                
                <p style="text-decoration: underline;" class="discoverBtn" @click="goodsListClick(17)">{{ lang=="en"?"Discover more":"发现更多" }}</p>
            </div>
        </div>

        <!--  -->
        <br>
        <div class="row">
            <!-- 内容第一行 元素 开始 -->
            <div style="position: relative;overflow: hidden;z-index:0;">
                <img class="col-md-12 clickBtn3Image" @mouseover="clickBtn1MouseOver()" @mouseout="clickBtn1MouseOut()"
                    style="height:60vh;width:100vw;object-fit:cover;object-position: top;z-index: -1;opacity:100%;filter: blur(1.5px);"
                    src="@/static/images/jianzi2.jpg" />
                <div v-show="lang=='en'"
                    style="position: absolute;top:20vh;width:90%;text-align: center;word-break: keep-all;word-wrap: normal;">
                    <h2 style="color:white;font-family: Jungka,sans-serif;font-weight: 500;">AruGaw World
                    </h2><br><br>
                    
                    <h5 style="color:white;font-family: Garamond,Baskerville,Caslon,serif;font-weight: 400;"><span
                            style="font-weight: 800;">{{ showNumber1.toFixed(0) }} </span>&nbsp; steps</h5><br>
                    <h5 style="color:white;font-family: Garamond,Baskerville,Caslon,serif;font-weight: 400;"><span
                            style="font-weight: 800;">{{ showNumber2.toFixed(0) }}&nbsp; </span>craftsmen<br><br>Serving nearly <span
                            style="font-weight: 800;">&nbsp;{{ showNumber3.toFixed(0) }}&nbsp;</span>&nbsp;countries in the world</h5>
                    <!-- <h5 style="color:white;font-family: Garamond,Baskerville,Caslon,serif;font-weight: 400;"><span
                            style="font-weight: 800;">{{ showNumber4.toFixed(0) }} </span>&nbsp; single-brand boutiques，
                    </h5>
                    <h5 style="color:white;font-family: Garamond,Baskerville,Caslon,serif;font-weight: 400;"><span
                            style="font-weight: 800;">{{ showNumber5.toFixed(0) }}</span> &nbsp; countries served.</h5>
                    <h5 style="color:white;font-family: Garamond,Baskerville,Caslon,serif;font-weight: 400;"><span
                            style="font-weight: 800;">{{ showNumber6.toFixed(0) }}</span>&nbsp; students with the aim of
                        becoming the master tailors of tomorrow.</h5> -->
                </div>

                <div v-show="lang=='zh'"
                    style="position: absolute;top:20vh;width:90%;text-align: center;word-break: keep-all;word-wrap: normal;">
                    <h2 style="color:white;font-family: Jungka,sans-serif;font-weight: 500;">诺尜 世界
                    </h2><br>
                    <h5 style="color:white;font-family: Garamond,Baskerville,Caslon,serif;font-weight: 400;"><span
                            style="font-weight: 800;">{{ showNumber1.toFixed(0) }} </span><span style="color:white;font-family: Jungka,sans-serif;font-weight: 500;">&nbsp;道工序</span></h5>
                    <br>
                    
                    <h5 style="color:white;font-family: Garamond,Baskerville,Caslon,serif;font-weight: 400;"><span
                            style="font-weight: 800;">{{ showNumber2.toFixed(0) }}&nbsp; </span>名匠人<br><br>服务世界近&nbsp;<span
                            style="font-weight: 800;">{{ showNumber3.toFixed(0) }}</span>&nbsp;个国家 </h5>
                    <!-- <h5 style="color:white;font-family: Garamond,Baskerville,Caslon,serif;font-weight: 400;"><span
                            style="font-weight: 800;">{{ showNumber4.toFixed(0) }} </span>&nbsp; single-brand boutiques，
                    </h5>
                    <h5 style="color:white;font-family: Garamond,Baskerville,Caslon,serif;font-weight: 400;"><span
                            style="font-weight: 800;">{{ showNumber5.toFixed(0) }}</span> &nbsp; countries served.</h5>
                    <h5 style="color:white;font-family: Garamond,Baskerville,Caslon,serif;font-weight: 400;"><span
                            style="font-weight: 800;">{{ showNumber6.toFixed(0) }}</span>&nbsp; students with the aim of
                        becoming the master tailors of tomorrow.</h5> -->
                </div>
            </div>
        </div><br>
        <!--  -->
    </div>
</div></template>
<style scoped>
.clickBtn1:hover,
.clickBtn2:hover {
    background-color: rgba(0, 0, 0, 0) !important;
    color: white !important;
    border: 1px solid white !important;
}


/* 小屏 */
@media screen and (max-width: 768px) {
    .btnSingle {
        width: 60% !important;
    }

    .csmaxshow {
        display: none;
    }

    .csminshow {
        display: inline-block;
    }

    .fourImg {
        height: 50vh !important;
        width: 100% !important;
    }

    .descText {
        padding: 10%;
        text-align: center;
        padding-top: 20%;
    }

}


/* 大屏 */
@media screen and (min-width: 768px) {
    .btnSingle {
        width: 15% !important;
    }

    .csmaxshow {
        display: inline-block;
    }

    .csminshow {
        display: none;
    }

    .fourImg {
        height: 100vh !important;
        width: 50% !important;
    }

    .descText {
        padding: 10%;
        text-align: center;
        padding-top: 20%;

    }
}

.center-vertical {
    position: relative;
    top: 30%;
    transform: translateY(-50%);
}

.center-horizontal {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.discoverBtn:hover {
    text-decoration: none !important;
    font-weight: 800;
    cursor: pointer;
}
</style>
<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
    data() {
        return {
            counter1: 0,//监控参数
            counterAc1: 207,//实际值
            showNumber1: 0,//展示的数字

            counter2: 0,//实际数字
            counterAc2: 340,//实际值
            showNumber2: 0,//展示的数字

            counter3: 0,//实际数字
            counterAc3: 15,//实际值
            showNumber3: 0,//展示的数字

            counter4: 0,//实际数字
            counterAc4: 12,//实际值
            showNumber4: 0,//展示的数字

            counter5: 0,//实际数字
            counterAc5: 16,//实际值
            showNumber5: 0,//展示的数字

            counter6: 0,//实际数字
            counterAc6: 27,//实际值
            showNumber6: 0,//展示的数字
        }
    },
    computed: {
       
        lang() {//语言
            return localStorage.getItem('language');
        }
    },
    mounted() {

        gsap.registerPlugin(ScrollTrigger);


        //第一张图
        gsap.to(".clickBtn1Image", {
            scrollTrigger: {
                trigger: ".clickBtn1Image",
                // markers: true,
                //toggleActions: "restart pause reverse pause"
                toggleActions: "restart",
            },
            scale: 1.2,
            duration: 5,
            delay: 0.5,
            onStart: () => {

            }
        });
        //第二张图
        gsap.to(".clickBtn2Image", {
            scrollTrigger: {
                trigger: ".clickBtn2Image",
                // markers: true,
                //toggleActions: "restart pause reverse pause"
                toggleActions: "restart",
            },
            scale: 1.2,
            duration: 5,
            delay: 0.5,
            onStart: () => {

            }
        });




        //第一组文字
        gsap.from(".descText1", {
            scrollTrigger: {
                trigger: ".fourImg1",
                // markers: true,
                //toggleActions: "restart pause reverse pause"
                toggleActions: "restart",
            },
            color: 'rgba(0,0,0,0)',
            //background:'white',
            x: -300,
            duration: 1.3,
            opacity: 0,
            delay: 0.1,
            ease: 'power2.inOut', // 关键帧整体的动画曲线设置,平滑
            //delay:3,
            onStart() {
            }
        });


        //第一张图片
        gsap.from(".fourImg1", {
            scrollTrigger: {
                trigger: ".fourImg1",
                // markers: true,
                //toggleActions: "restart pause reverse pause"
                toggleActions: "restart",
            },
            duration: 1.3,
            x: 300,
            opacity: 0,
            delay: 0.1,
            ease: 'power2.inOut', // 关键帧整体的动画曲线设置,平滑
            onStart() {
            }
        });


        //第二组文字
        gsap.from(".descText2", {
            scrollTrigger: {
                trigger: ".fourImg2",
                // markers: true,
                //toggleActions: "restart pause reverse pause"
                toggleActions: "restart",
            },
            color: 'rgba(0,0,0,0)',
            //background:'white',
            x: 300,
            duration: 1.2,
            opacity: 0,
            ease: 'power2.inOut', // 关键帧整体的动画曲线设置,平滑
            delay: 0.1,
            onStart() {
                console.log("66666");
            }
        });

        //第二张图片
        gsap.from(".fourImg2", {
            scrollTrigger: {
                trigger: ".fourImg2",
                // markers: true,
                //toggleActions: "restart pause reverse pause"
                toggleActions: "restart",
            },
            duration: 1.2,
            x: -300,
            opacity: 0,
            delay: 0.1,
            ease: 'power2.inOut', // 关键帧整体的动画曲线设置,平滑
            onStart() {
            }
        });
        //第三张图片

        var that = this;
        gsap.from(".clickBtn3Image", {
            scrollTrigger: {
                trigger: ".clickBtn3Image",
                // markers: true,
                //toggleActions: "restart pause reverse pause"
                toggleActions: "restart",
            },
            background: 'rgba(0,0,0,0)',
            opacity: 0,
            scale: 1.5,
            duration: 2.5,
            delay: 0.5,
            onStart() {
                console.log("33333333333");
                that.counter1 = 0;
                that.counter2 = 0;
                that.counter3 = 0;
                that.counter4 = 0;
                that.counter5 = 0;
                that.counter6 = 0;
                setInterval(() => {
                    that.counter1 = that.counterAc1;
                    that.counter2 = that.counterAc2;
                    that.counter3 = that.counterAc3;
                    that.counter4 = that.counterAc4;
                    that.counter5 = that.counterAc5;
                    that.counter6 = that.counterAc6;
                }, 200)
            }
        });

    },
    watch: {
        //监听变化,实现动态过渡
        counter1(newValue) {
            gsap.to(this, { duration: 1.3, showNumber1: newValue, delay: 0.2, })
        },
        counter2(newValue) {
            gsap.to(this, { duration: 1.3, showNumber2: newValue, delay: 0.4, })
        },
        counter3(newValue) {
            gsap.to(this, { duration: 1.3, showNumber3: newValue, delay: 0.6, })
        }, counter4(newValue) {
            gsap.to(this, { duration: 1.3, showNumber4: newValue, delay: 0.8, })
        }
        , counter5(newValue) {
            gsap.to(this, { duration: 1.3, showNumber5: newValue, delay: 1, })
        }
        , counter6(newValue) {
            gsap.to(this, { duration: 1.3, showNumber6: newValue, delay: 1.2, })
        }
    },
    methods: {
        goodsListClick(typeId) {
            console.log("当前:" + this.$route.path);
                //跳转
                this.$router.push({
                    name: 'goodsList',
                    query: {
                        'typeId': typeId,    //跳路由
                    }
                });

                //EventBus.$emit("IndexNavbarMsgGoodsList", typeId);
            
        },
        clickBtn() {
            console.log("dayin");
        },
        clickBtn1MouseOver() {
            gsap.to(".clickBtn1Image", {

                scale: 1.5,
                duration: 5,
                onStart: () => {

                }
            });
        },
        clickBtn1MouseOut() {
            gsap.globalTimeline.kill();
            gsap.to(".clickBtn1Image", {
                scale: 1,
                duration: 6,
                onStart: () => {

                }
            });
        },
        clickBtn2MouseOver() {
            gsap.to(".clickBtn2Image", {

                scale: 1.5,
                duration: 5,
                onStart: () => {

                }
            });
        },
        clickBtn2MouseOut() {
            gsap.globalTimeline.kill();
            gsap.to(".clickBtn2Image", {
                scale: 1,
                duration: 6,
                onStart: () => {

                }
            });
        },
    }
}
</script>
