// 创建文件en/index.js
const en = {
    nvshi:"WOMAN",
    nanshi:"MAN",
    name: 'test',
    shouye:'HOME',
    gouwuche:'CART',
    guanyu:'ABOUT',
    shangpinliebiao:'Goods List',
    goodslist_paixu_title:"ORDER",
    goodslist_paixu_sale_count:"SALES VOLUME",
    goodslist_paixu_time:"LATEST",
    goodslist_shouye:"FIRST",
    goodslist_weiye:"END",
    goodslist_xiayiye:"NEXT",
    goodslist_shangyiye:"LAST",

    goodslist_products:"PRODUCTS",

    goods_jiarugouwuche:"ADD TO CART",

  }
  export default en