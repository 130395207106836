<template>
    <div id="carouselExample" class="carousel slide">
        <div class="carousel-inner" style="padding:0 15%;">
            <div class="carousel-item active" style="width:100%">
                <div class="row" style="width:100% !important;">
                    <div class="col-md-12 col-lg-3" style="">
                        <!-- <img :src="da1[0][0].img"/> -->

                        <div class="card" style="width: 100%;position: relative;">
                            <div>
                                <img :src="da1[0][0].img" class="card-img-top card-img">
                            </div>
                            <div class="card-body">
                                <p class="card-text">
                                    <p>{{ da1[0][0].position }}</p>
                                    <p>{{ da1[0][0].desc }}</p>
                                    <p>{{ da1[0][0].email }}</p>
                                    <p>{{ da1[0][0].phone }}</p>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-3" style="">
                        
                        <div class="card" style="width: 100%;">
                            <img :src="da1[0][1].img" class="card-img-top card-img" alt="...">
                            <div class="card-body">
                                <p class="card-text">
                                    <p>{{ da1[0][0].position }}</p>
                                    <p>{{ da1[0][0].desc }}</p>
                                    <p>{{ da1[0][0].email }}</p>
                                    <p>{{ da1[0][0].phone }}</p>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-3" style="">
                        
                        <div class="card" style="width: 100%;">
                            <img :src="da1[0][2].img" class="card-img-top card-img" alt="...">
                            <div class="card-body">
                                <p class="card-text">
                                    <p>{{ da1[0][0].position }}</p>
                                    <p>{{ da1[0][0].desc }}</p>
                                    <p>{{ da1[0][0].email }}</p>
                                    <p>{{ da1[0][0].phone }}</p>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-3" style="">
                        
                        <div class="card" style="width: 100%;">
                            <img :src="da1[0][3].img" class="card-img-top card-img" alt="...">
                            <div class="card-body">
                                <p class="card-text">
                                    <p>{{ da1[0][0].position }}</p>
                                    <p>{{ da1[0][0].desc }}</p>
                                    <p>{{ da1[0][0].email }}</p>
                                    <p>{{ da1[0][0].phone }}</p>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                222
            </div>
            <div class="carousel-item">
                333
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev"
            style="">
            <span class="carousel-control-prev-icon" aria-hidden="false"></span>
            <span class="visually-hidden">Previous</span>
            <i class="icon icon-font icon-xiangxiazhankai-yuankuang" style="font-size: 50px;color: rgba(0, 0, 0, 0.5);rotate: 90deg;"></i>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next"
            style="">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
            <i class="icon icon-font icon-xiangxiazhankai-yuankuang" style="font-size: 50px;color: rgba(0, 0, 0, 0.5);rotate: -90deg;"></i>
        </button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            da1: [
                [
                    {
                        num: 0,
                        img: 'https://cdn.shopifycdn.net/s/files/1/0427/8914/2689/files/Nur-Sultan_400x.progressive.jpg?v=1614366506',
                        position: '阿斯塔纳 - 哈萨克斯坦',
                        desc: 'Dostyk 16， Talan 画廊 -',
                        email: '12345@qq.com',
                        phone: '+7 702 872 68 81',
                    },
                    {
                        num: 1,
                        img: 'https://cdn.shopifycdn.net/s/files/1/0427/8914/2689/files/Baku_400x.progressive.JPG?v=1615927655',
                        position: '阿斯塔纳 - 哈萨克斯坦',
                        desc: 'Dostyk 16， Talan 画廊 -',
                        email: '12345@qq.com',
                        phone: '+7 702 872 68 81',
                    },
                    {
                        num: 2,
                        img: 'https://cdn.shopifycdn.net/s/files/1/0427/8914/2689/files/Cannes_400x.progressive.JPG?v=1615927655',
                        position: '阿斯塔纳 - 哈萨克斯坦',
                        desc: 'Dostyk 16， Talan 画廊 -',
                        email: '12345@qq.com',
                        phone: '+7 702 872 68 81',
                    },
                    {
                        num: 3,
                        img: 'https://cdn.shopifycdn.net/s/files/1/0427/8914/2689/files/Capri_400x.progressive.jpg?v=1614354424',
                        position: '阿斯塔纳 - 哈萨克斯坦',
                        desc: 'Dostyk 16， Talan 画廊 -',
                        email: '12345@qq.com',
                        phone: '+7 702 872 68 81',
                    },

                ],
                [
                    {
                        num: 4,
                        img: 'https://cdn.shopifycdn.net/s/files/1/0427/8914/2689/files/Nur-Sultan_400x.progressive.jpg?v=1614366506',
                        position: '阿斯塔纳 - 哈萨克斯坦',
                        desc: 'Dostyk 16， Talan 画廊 -',
                        email: '12345@qq.com',
                        phone: '+7 702 872 68 81',
                    },
                    {
                        num: 5,
                        img: 'https://cdn.shopifycdn.net/s/files/1/0427/8914/2689/files/Nur-Sultan_400x.progressive.jpg?v=1614366506',
                        position: '阿斯塔纳 - 哈萨克斯坦',
                        desc: 'Dostyk 16， Talan 画廊 -',
                        email: '12345@qq.com',
                        phone: '+7 702 872 68 81',
                    },
                    {
                        num: 6,
                        img: 'https://cdn.shopifycdn.net/s/files/1/0427/8914/2689/files/Nur-Sultan_400x.progressive.jpg?v=1614366506',
                        position: '阿斯塔纳 - 哈萨克斯坦',
                        desc: 'Dostyk 16， Talan 画廊 -',
                        email: '12345@qq.com',
                        phone: '+7 702 872 68 81',
                    },
                    {
                        num: 7,
                        img: 'https://cdn.shopifycdn.net/s/files/1/0427/8914/2689/files/Nur-Sultan_400x.progressive.jpg?v=1614366506',
                        position: '阿斯塔纳 - 哈萨克斯坦',
                        desc: 'Dostyk 16， Talan 画廊 -',
                        email: '12345@qq.com',
                        phone: '+7 702 872 68 81',
                    },

                ]

            ]
        }
    },
    mounted() {
        console.log(this.da1[0][0].img);
    }
}
</script>
<style>

/* card组件的图片 */
.card-img{
    height:200px;
    object-fit: cover;
}
</style>
