<!-- 页脚 -->
<template>
    <div>
        <div class="container text-center">
            <!-- Columns start at 50% wide on mobile and bump up to 33.3% wide on desktop -->
            <div class="row">
                <div class="col-xs-12 col-sm-3 " style="text-align: left;">
                    <img src="../static/images/logo.png" style="width:100px;" :style="{marginLeft:$store.state.scrollState.widthSB > $store.state.scrollWidthFixed.small.symbol?'0':'30px'}">
                </div>
                <div class="col-xs-12 col-sm-3 ">
                    <ul style="list-style: none;text-align: left;">
                        <li class="top1">{{ lang=='en'?'FOLLOW US ON':'继续关注我们' }}</li>
                        <li class="content1"> {{ lang=='en'?'AruGaw':'诺尜' }}</li>
                    </ul>
                    <ul style="list-style: none;text-align: left;">
                        <!-- <li class="top1">CUSTOMER SERVICE</li>
                        <li class="content1"> Boutiques</li> -->
                        <li class="top1">{{ lang=='en'?'Office Address':'办公地址' }}</li>
                        <li class="content1"> {{ lang=='en'?'27th Floor，Harbor Center,No.1 Wuwu Road,Zhongshan District,Dalian.Liaoning China':'辽宁省大连市中山区五五路1号港湾中心27层' }}</li>
                        <li class="top1">{{ lang=='en'?'contactinfo':'联系方式' }}</li>
                        <li class="content1"> {{ lang=='en'?'+86-0411-83769138':'0411-83769138' }}</li>
                    </ul>
                </div>
                <div class="col-xs-12 col-sm-3 ">
                    <ul style="list-style: none;text-align: left;">
                        <li class="top1">{{ lang=='en'?'LEGAL INFORMATION':'法律信息' }}</li>
                        <li class="content1"> {{ lang=='en'?'Terms & Conditions':'条款和条件' }}</li>
                        <li class="content1">{{ lang=='en'?'GDPR Compliance':'通用数据保护条例 合规性' }}</li>
                        <li class="content1"> {{ lang=='en'?'Privacy Policy':'隐私条款' }}</li>
                        <li class="content1"> {{ lang=='en'?'Cookie Policy':'Cookie政策' }}</li>
                        <li class="content1"> {{ lang=='en'?'Gender Equality Policy':'两性平等政策' }}</li>
                    </ul>
                </div>
                <div class="col-xs-12 col-sm-3 ">
                    <ul style="list-style: none;text-align: left;">
                        <li class="top1">{{ lang=='en'?'NEWSLETTER':'新闻稿' }}</li>
                        <li class="content1">{{ lang=='en'?'Subscribe to get notified about product launches, special offers and company news':'订阅以获取有关产品发布、特别优惠和公司的通知消息' }}</li>
                        <!-- <li class="content1"> GDPR Compliance</li> -->
                            <li><br><input v-model="form1.email" type="email" class="form-control" style="box-shadow: none !important;border:1px solid black !important;border-radius: 18px !important;" id="email" :placeholder="lang=='en'?'email':'邮箱'"
                                    aria-describedby="emailHelp"><br></li>
                            <li><input class="form-control" v-model="form1.content" style="box-shadow: none !important;border:1px solid black !important;border-radius: 18px !important;" id="content" :placeholder="lang=='en'?'leave a message':'留言'"
                                    aria-describedby="emailHelp"><br></li>
                       <li style="float:right">
                        <button type="button"  class="btn btn-dark clickBtn2 btn-lg" @mouseover="submitBtnMouseOver()"  @mouseout="submitBtnMouseOut()" @click="submitForm1()"
                                style="font-family: Jungka,sans-serif;border-radius:20px !important;font-size: 12px;padding:10px 20px;white-space: nowrap;width: fit-content;min-width: 20%;" >{{ lang=='en'?'SUBSCRIBE':'订阅' }}</button> 
                        </li>
                    </ul>
                </div>
            </div>
<!--  -->
<!-- Button trigger modal -->
<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" style="display: none;" ref="myModal">
  Launch demo modal
</button>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- <div class="modal-header">
         <h1 class="modal-title fs-5" id="exampleModalLabel">{{ myModal.title }}</h1>
      
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div> -->
      <div class="modal-body">
        <img v-show="myModal.title=='fail'" src="../static/images/fail.png" style="width:20px;">
        <img v-show="myModal.title=='success'" src="../static/images/success.png" style="width:20px;">  &nbsp;&nbsp;{{ myModal.content }}
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="float: right;"></button>
      </div>
    </div>
  </div>
</div>

<!--  --><br>
            <div class="row">
                <div class="col-xs-12 col-sm-12" style="display: flex;padding-bottom: 40px;" :style="{justifyContent:$store.state.scrollState.widthSB > $store.state.scrollWidthFixed.small.symbol?'flex-start':'space-between'}">
                    <svg class="icon" aria-hidden="true" style="font-size: 40px;">
                        <use xlink:href="#icon-Amex-2" ></use>
                    </svg>
                    <svg class="icon" aria-hidden="true" style="font-size: 40px;">
                        <use xlink:href="#icon-Applepay"></use>
                    </svg>
                    <svg class="icon" aria-hidden="true" style="font-size: 40px;">
                        <use xlink:href="#icon-pay-03"></use>
                    </svg>
                    <svg class="icon" aria-hidden="true" style="font-size: 40px;">
                        <use xlink:href="#icon-maestro"></use>
                    </svg>
                    <svg class="icon" aria-hidden="true" style="font-size: 40px;">
                        <use xlink:href="#icon-mastercard"></use>
                    </svg>
                    <svg class="icon" aria-hidden="true" style="font-size: 40px;">
                        <use xlink:href="#icon-icon_Internet"></use>
                    </svg>
                    <svg class="icon" aria-hidden="true" style="font-size: 40px;">
                        <use xlink:href="#icon-visa"></use>
                    </svg>
              
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.top1 {
    font-family: Jungka, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
    padding-top: 4px;
    text-transform: uppercase;
}

.content1 {
    color: #000;
    font-family: Jungka, sans-serif;
    font-size: 12px;
    line-height: 22px;
    font-weight: 600;
}
.icon {
       width: 1em; height: 1em;
       vertical-align: -0.15em;
       fill: currentColor;
       overflow: hidden;
}

</style>
<script>
import { gsap } from "gsap";
    export default{
        data(){
            return{
                form1:{//客户留言提交的表单
                    email:"",
                    content:""
                },
                myModal:{
                    title:"",
                    content:""
                }

            }
        },
        methods:{
            //提交按钮
            submitForm1(){
                console.log(JSON.stringify(this.form1));
                if(this.form1.email.trim()==""){
                    console.log("email为空!");
                    //模拟点击
                    this.$refs.myModal.click();
                    if(this.lang=='en'){
                        this.myModal.title='fail';
                        this.myModal.content='please input email';
                    }else{
                        this.myModal.title='fail';
                        this.myModal.content='请输入电子邮箱';
                    }
                    return;
                }
                //新增
                this.getGoodsSize();

            },

            getGoodsSize() {//提交信息
                var that = this;
                that.$axios.post(that.$store.state.requestUrl.emailSubmit,that.form1
                ).then(function (response) {
                    console.log("response:"+response.data.code);
                    if(response.data.code==200){
                        //操作成功
                        that.$refs.myModal.click();
                        if(that.lang=='en'){
                            that.myModal.title='success';
                            that.myModal.content='Thank you for your support!';
                        }else{
                            that.myModal.title='success';
                            that.myModal.content='感谢您的支持!';
                        }
                        that.form1.content="";
                        that.form1.email="";
                    }else if(response.data.code==501){
                        //操作失败,重复
                        that.$refs.myModal.click();
                        if(that.lang=='en'){
                            that.myModal.title='fail';
                            that.myModal.content='Email already exists!';
                        }else{
                            that.myModal.title='fail';
                            that.myModal.content='邮箱已经存在!';
                        }
                    }else{
                        //操作失败
                        that.$refs.myModal.click();
                        if(that.lang=='en'){
                            that.myModal.title='fail';
                            that.myModal.content='Please try again!';
                        }else{
                            that.myModal.title='fail';
                            that.myModal.content='请重试!';
                        }
                    }
                })
                    .catch(function (error) {
                    });

                },

            submitBtnMouseOver() {
            gsap.to(".submitBtn", {
                duration:0.3,
                background:"black",
                color:"white",
                onStart: () => {

                }
            });
        },

        submitBtnMouseOut() {
            gsap.to(".submitBtn", {
                background:"white",
                color:"black",
                duration:0.3,
                onStart: () => {

                }
            });
        },
        },
        computed: {
    lang() {//语言
            return localStorage.getItem('language');
        }

  },
    }
</script>

