import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  //存储对象
  /**
   * 
   1.方法1
   computed:{
        // 使用计算属性表达
        title( ){
            return this.$store.state.scrollState.scrolling;
        },  
    },
    2.方法2(mapState)
      
    引入mapState
    import {mapState} from 'vuex';

    
    computed:{
    
        // 利用mapState辅助函数的简写形式，代替以上的语句，但本质也是生成计算属性
        ...mapState(['title','singer']),
       
    },
   */
  state: {



    /**
     * 数据结构
     *  {
        "MAN": [
        ],
        "WOMAN": [
        ]
    }
     */
    menuInfo:[],//菜单信息(首页赋值),

    requestUrl:{
        getMenuList:"https://gwmallapi.arugaw-bespoke.com/gwMall/mallGoodsType/selectMenuShow",//获取菜单信息
        selectGoodsDetailsByTypeId:"https://gwmallapi.arugaw-bespoke.com/gwMall/mallGoodsDetails/selectGoodsDetailsByTypeId",  //查询指定typeId的..参数typeId,pageNum1,pageSize1,orderStr1
        selectMallGoodsSizeByDetailsId:"https://gwmallapi.arugaw-bespoke.com/gwMall/mallGoodsSize/selectMallGoodsSizeByDetailsId/",//查询指定details_id的size,参数是details_id
        selectMallGoodsDetailsFileinfoByDetailsId:"https://gwmallapi.arugaw-bespoke.com/gwMall/detailsFileinfo/selectMallGoodsDetailsFileinfoByDetailsId/",//查询指定details_id的轮播图,参数是details_id
        selectMallGoodsDetailsByGoodId:"https://gwmallapi.arugaw-bespoke.com/gwMall/mallGoodsDetails/selectByGoodId/",//查询指定goodId的信息
        emailSubmit:"https://gwmallapi.arugaw-bespoke.com/gwMall/mallGoodsGuestMessage",//邮箱提交地址
      },

    //屏幕状态
    scrollState: {
      width: -1,//屏幕宽度
      height: -1,//屏幕高度
      scrolling: -1,//距离顶端滚动了少
      widthSB: -1,//实时屏幕宽度标识,对应scrollWidthFixed.symbol
    },
    syslanguageTemplate:{//sys.language的模版(可选参数)
       english:'en',//英语
       chinese:'zh' //中文
    },
    //自适应书写模板
    //$store.state.scrollState.widthSB > $store.state.scrollWidthFixed.medium.symbol
    scrollWidthFixed:
    {
      extraSmall: {
        min: 0,//含
        max: 576,//不含
        symbol: 0,
      },
      small: {
        min: 576,//含
        max: 768,//不含
        symbol: 1,
      },
      medium: {
        min: 768,//含
        max: 992,//不含
        symbol: 2,
      },
      large: {
        min: 992,//含
        max: 1200,//不含
        symbol: 3,
      }
      ,xLarge: {
        min: 1200,//含
        max: 1400,//不含
        symbol: 4,
      }
      ,xXLarge: {
        min: 1400,//含
        max: 99999999999,//不含,无穷大
        symbol: 5,
      }
      ,unknown: {
        min: -2,//含
        max: -2,//不含,无穷大
        symbol: -2,
      }
    }
  },
  //构造器
  //从 store 中的 state 中派生出一些状态
  /**
   * 
   1.方法1
   computed:{
        // 使用计算属性表达
        changeScrollWidthGetter(){
            return this.$store.getters.changeScrollWidthGetter;
        }
    },
    2.方法2(mapGetters)
      
    引入mapGetters
    import {mapGetters} from 'vuex';

    
    computed:{
    
        // 利用mapGetters辅助函数的简写形式，代替以上的语句，但本质也是生成计算属性
        ...mapGetters(['changeScrollWidthGetter']),
       
    },
   */
  getters: {
    changeScrollStateWidthGetter(value) {
      return state.scrollState.width = value;
    }

  },
  //操作state中的数据
  /**
   * 
   1.方法1
    <button @click="changeScrollWidth('123')">更改屏幕宽度</button>

     屏幕宽度
    changeScrollWidth(str){
        this.$store.commit('changeScrollWidth',str);
    },
   *******************************************************************
    2.方法2(mapMutations)
      
    引入mapMutations
    import {mapMutations} from 'vuex';

    // 利用mapMutation辅助函数的简写形式
    ...mapMutations(['changeScrollWidth']),


   */
  mutations: {

    //变更屏幕宽度,value是变更的当前值
    changeScrollStateWidth(state, value) {
      state.scrollState.width = value;
    //  console.log("屏幕宽度:" + state.scrollState.width);
    },
    changeScrollStateScrolling(state, value) {
      state.scrollState.scrolling = value;
     // console.log("屏幕滚动:" + state.scrollState.scrolling);
    },
    //变更屏幕宽度标识,value是变更的宽度标识值
    changeScrollStateWidthSB(state, value) {
      state.scrollState.widthSB = value;
   //   console.log("屏幕宽度标识:" + state.scrollState.widthSB);
    },

    //更新MenuInfo数据
    changeMenuInfo(state,value){
      state.menuInfo = value;
     // console.log("菜单更新:"+JSON.stringify(state.menuInfo));
    },


  },
  //Action 提交的是 mutation，而不是直接变更状态!
  /**
    * 
    1.方法1
    
  <button @click="changeScrollWidthAction('123')">三秒后更更改屏幕宽度/button>
 
      //3秒后更改屏幕宽度
      changeScrollWidthAction(str){
         setTimeout(()=>{
             this.$store.dispatch('changeScrollWidthAction',str);
         },3000)
     },
    *******************************************************************
     2.方法2(mapActions)
       
     mapActions
     import {mapActions} from 'vuex';
 
     // 利用mapMutation辅助函数的简写形式
     ...mapActions(['changeScrollWidthAction']),
 
 
    */
  actions: {

    changeScrollStateWidthGetterAction(context, value) {
      setTimeout(() => {
        context.commit('changeScrollStateWidth', value);
      }, 3000)
    },

  },
  //子模块
  modules: {
  }
})
