import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import i18n from './utils/i18n'

//阿里图标
import './static/font/iconfont.css'
//公共css
import './static/css/common.css'
// 使用插件vue-directive-image-previewer,开始
import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'
Vue.use(VueDirectiveImagePreviewer,{
  zIndex: 99999999,  //层级显示
  previewSize: 3 //三倍图
}) 
//使用插件vue-directive-image-previewer,结束

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';

import axios from '../node_modules/axios'
Vue.prototype.$axios = axios;
Vue.config.productionTip = false


new Vue({
  router,
  store,
  i18n,
  axios,
  render: function (h) { return h(App) }
}).$mount('#app')
