<template>
  <div id="app">
    
    <IndexNavbar v-if="$store.state.scrollState.widthSB > $store.state.scrollWidthFixed.medium.symbol"></IndexNavbar>
    <IndexNavbarMobile v-if="$store.state.scrollState.widthSB <= $store.state.scrollWidthFixed.medium.symbol"></IndexNavbarMobile>
    <router-view />
  </div>
</template>
<script>

import IndexNavbarMobile from '../src/components/Index-Navbar-Mobile.vue'
import IndexNavbar from '../src/components/Index-Navbar.vue'
import {screenWidthListener,scrollingCheckListener} from '../src/static/js/main/screen'
import {initLanguage} from '../src/static/js/main/language' 

export default {
  components: {
    IndexNavbar,
    IndexNavbarMobile,
  },
  mounted() {
    //监听屏幕宽度变化
    screenWidthListener();
    //监听页面滚动事件,页面滚动时,触发函数scrollingCheck
    scrollingCheckListener();

    //语言设置
    initLanguage();
    

  },

}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

html {
  overflow-y: scroll;
}

:root {
  overflow-y: auto;
  overflow-x: hidden;
}

:root body {
  position: absolute;
}

body {
  width: 100vw;
  overflow: hidden;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
