<template>
  <nav class="navbar bg-body-tertiary fixed-top" style="z-index:99;"
    :style="{ background: scrolling > 0 ? backgroundColor2 : backgroundColor1 }">

    <ul style="width: 100%;list-style: none;border-bottom:1px solid rgba(255,255,255,0.3);" :style="[{ paddingBottom: scrolling > 0 ? '0' : '20px' }
    ]">
      <li v-show="scrolling == 0"
        style="background-color: black;width:100% !important;background:cover;position: absolute;left: 0;top:0;height:20px;">
        <div
          style="background-color: black;width:100wv !important;background:cover;color:white;font-family: Jungka,sans-serif;font-size: 12px;padding:6px 0;font-weight: 600;">
          THE BEST OF THE BEST +1
        </div>
      </li>
      <li style="width;100%;" :style="[{ background: scrolling > 0 ? backgroundColor2 : backgroundColor1 },
      { paddingTop: scrolling > 0 ? '10px' : '40px' }
      ]">
        <div class="container-fluid" style="position: relative;">
          <!-- <a class="navbar-brand" href="#">AruGaw</a> -->
          <!-- 返回按钮 -->
          <i class="icon icon-font icon-zhixiangzuo"
            style="position:absolute;left:-20px;top:5px;font-size: 30px;padding:0px 0px;margin-right:60px;border-radius: 3px;"
            :class="[scrolling > 0 ? 'colorBlack' : 'colorWhite']" @click="clickSearch()"></i>
          <!-- 菜单按钮 -->
          <a data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
            style="position:absolute;left:20px;top:5px;border:none !important;padding:0 !important;background-color: rgba(0,0,0,0) !important;">
            <i class="icon icon-font icon-caidan" style="font-size: 30px;padding:10px;border-radius: 3px;"
              :class="[scrolling > 0 ? 'colorBlack' : 'colorWhite']"></i>
          </a>

          <!-- 菜单按钮 -->

          <!-- logo图片 -->
          <img src="@/static/images/logo.png" style="height:40px;margin-right: 30px;" @click="changePage('/')"
            class="navbar-brand logoMobile" />
          <!-- logo图片 -->

          <!-- 右方区域 -->
          <div style="position: absolute;right: 5px;top:5px;">

            <!-- 购物车按钮 -->
            <a data-bs-toggle="offcanvas" data-bs-target="#cartNavbar" aria-controls="cartNavbar"
              aria-label="Toggle navigation"
              style="position:absolute;right:40px;top:0px;border:none;padding:0;background-color: rgba(0,0,0,0) !important;">
              <i class="icon icon-font icon-gouwuche" style="font-size: 30px;padding:30px 10px;border-radius: 3px;"
                :class="[scrolling > 0 ? 'colorBlack' : 'colorWhite']"></i>
            </a>
            <!-- 语言按钮 -->
            <i class="icon icon-font" @click="setLanguage()"
              style="position:absolute;right:0px;top:2px;border:none;padding-bottom: 50px;font-size: 30px;font-weight: 100;"
              :class="[scrolling > 0 ? 'colorBlack' : 'colorWhite', language === 'zh' ? 'icon-yingwen1' : 'icon-zhongwen1']"></i>

          </div>
          <!-- 右方区域 -->

          <!-- 菜单列表 -->
          <div style="width:80%;" class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel">
            <div class="offcanvas-header">
              <!-- <h5 class="offcanvas-title" id="offcanvasNavbarLabel">AruGaw</h5> -->
              <img src="@/static/images/logo.png" style="height:40px;margin:0 auto;" class="navbar-brand logoMobile"
                @click="changePage('/')" />
              <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"
                ref="offcanvas"></button>
            </div>
            <div class="offcanvas-body">
              <ul class="navbar-nav justify-content-end flex-grow-1 pe-3" style="text-align: left;">
                <!-- <li class="nav-item">
                  <a class="nav-link" @click="changePage('/')">{{ $t('shouye') }}</a>
                </li> -->
                <!-- <li class="nav-item">
                  <a class="nav-link active" style="background-color: rgba(220, 53, 69, 0.3);" aria-current="page"
                    href="#">{{ $t('name') }} &nbsp;<span class="badge rounded-pill text-bg-danger">HOT</span></a>
                </li> -->
                <!-- <li class="nav-item">
                  <a class="nav-link" @click="changePage('/goodsList')">{{ $t('shangpinliebiao') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">{{ $t('guanyu') }}</a>
                </li> -->
                <!-- <li class="nav-item">
                  <a class="dropdown-item" style="background-color: rgba(25, 135, 85, 0.05);" href="#">Action
                    &nbsp;
                    <i class="icon icon-font icon-xinpin" style="font-size: 24px;color:rgba(23,124,77,0.9)"></i>

                  </a>
                </li> -->
                <!-- <li class="nav-item">
                  <a class="nav-link" href="#">Link</a>
                </li>
                <li class="nav-item">
                  <a class="dropdown-item" style="background-color: rgba(203,49,64,0.1);" href="#">Action
                    &nbsp;
                    <i class="icon icon-font icon-remenkechenghongse"
                      style="font-size: 24px;color:rgba(203,49,64,0.9)"></i>

                  </a>
                </li> -->
                <li class="nav-item dropdown" ref="dropDown1" style="background:white !important" v-show="menuData.MAN.length>0">
                  <a href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"
                    style="color:black;text-decoration: none !important;background:white !important;border: 0 !important;margin:0 !important;padding:0 !important;display: flex;justify-content: space-between;">
                    <div>{{ $t('nanshi') }}</div>
                    <div id="dropDown1">{{ dropIcon1 }}</div>
                  </a>
                  <ul class="dropdown-menu"
                    style="border:none !important;border-bottom:1px solid black !important;border-bottom-left-radius: 0%;border-bottom-right-radius: 0%;">
                    <li><a class="dropdown-item" href="#" v-for="(item, index) in menuData.MAN"
                        @click="goodsListClick(item.id)">{{ language == 'en' ? item.label_en : item.label_zh }}</a></li>
                  </ul>
                </li>
                <li class="nav-item dropdown" v-show="menuData.WOMAN.length>0">
                  <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    {{ $t('nvshi') }}
                  </a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#" v-for="(item, index) in menuData.WOMAN"
                        @click="goodsListClick(item.id)">{{ language == 'en' ? item.label_en : item.label_zh }}</a></li>
                  </ul>
                </li>
              </ul>
              <!-- <form class="d-flex mt-3" role="search">
                <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                <button class="btn btn-outline-success" type="submit">Search</button>
              </form> -->
            </div>
          </div>
          <!-- 菜单列表 -->

          <!-- 购物车列表 -->
          <div style="width:80%;" class="offcanvas offcanvas-end" tabindex="-1" id="cartNavbar"
            aria-labelledby="offcanvasNavbarLabel">
            <div class="offcanvas-header">
              <!-- <h5 class="offcanvas-title" id="offcanvasNavbarLabel">AruGaw</h5> -->
              <span>{{ $t('gouwuche') }}</span>
              <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">

            </div>
          </div>
          <!-- 购物车列表 -->



        </div>
      </li>
    </ul>
  </nav>
</template>
<script>
import { EventBus } from "../event-bus.js"

import Footer from '@/components/Footer.vue'
import router from "@/router";
import { gsap } from "gsap";
export default {
  components: {
    Footer,
  },
  data() {
    return {
      //底色根据滚动位置而动态变化
      backgroundColor1: 'rgba(0,0,0,0) !important',
      backgroundColor2: 'rgba(255,255,255,1) !important',

      language: '',

      currentRoute: null,

      whiteBgList: ["/goodsView"],//包含的页面直接白色底,不做效果切换
      menuData: {
        "MAN": [
        ],
        "WOMAN": [
        ]
      },
      typeIdHis: -1,//typeid历史

      dropIcon1: "+",//男士下拉菜单的图标

    }
  },
  computed: {
    //y滚动的位置
    scrolling() {
      if (this.whiteBgList.includes(this.currentRoute)) {
        //直接白色底
        console.log("直接白色底");
        return "1";
      } else {
        return this.$store.state.scrollState.scrolling;
      }
    }
  },
  mounted() {
    console.log("mounted");
    this.initLanguage();
    this.currentRoute = this.$route.path
    this.$watch('$route', (to, from) => {
      this.currentRoute = to.path
    })
    this.logoAnimation();
    //初始化菜单数据
    this.getMenu();
    //监听下拉隐藏事件
    this.dropDownHiddenListener1();
    //监听下拉显示事件
    this.dropDownShowListener1();



  },
  methods: {
    dropDownHiddenListener1() {
      var that = this;
      that.$refs.dropDown1.addEventListener('hide.bs.dropdown', function (event) {
        console.log("隐藏事件触发");
        
        gsap.to("#dropDown1", {
          rotation:-0,
          duration: 0.5,
          ease: 'power2.inOut', // 关键帧整体的动画曲线设置,平滑
          delay:0,
          onStart() {
            console.log("阿萨德");
           // that.dropIcon1 = "+";
          }
        })
      })
    },
    dropDownShowListener1() {
      var that = this;
      that.$refs.dropDown1.addEventListener('show.bs.dropdown', function (event) {
        console.log("显示事件触发");
        gsap.to("#dropDown1", {
          //background:'white',
          //rotateY:"360deg",
          rotation:45,
          duration: 0.5,
          ease: 'power2.inOut', // 关键帧整体的动画曲线设置,平滑
          delay:0,
          onStart() {
            console.log("阿萨德");
          }
        })
      })
    },

    clickSearch() {
      this.$router.back();
      console.log("按下搜索");
    },
    //点击商品,页面跳转
    goodsListClick(typeId) {
      this.closeAlert();
      console.log("当前:" + this.$route.path);
      if (this.$route.path != "/goodsList") {

        this.typeIdHis = -1;
        // EventBus.$emit("IndexNavbarMsgGoodsList", typeId);
        //跳转
        this.$router.push({
          name: 'goodsList',
          query: {
            'typeId': typeId,    //跳路由
          }
        });

      } else {
        //通知goodsList页面,当前用户更换了typeId
        console.log(this.typeIdHis);
        console.log(typeId);
        if (this.typeIdHis != typeId) {
          this.typeIdHis = typeId;//记录typeId历史
          EventBus.$emit("IndexNavbarMsgGoodsList", typeId);
          console.log("")
          var that = this;
          setTimeout(() => {
            that.$router.push({
              name: 'goodsList',
              query: {
                'typeId': typeId,    //跳路由
              },
            });
          }, 0)
        }



      }





    },
    getMenu() {//获取菜单//this.$store.state.requestUrl.getMenuList
      var that = this;
      that.$axios.get(that.$store.state.requestUrl.getMenuList)
        .then(function (response) {
          console.log("response:" + JSON.stringify(response.data.data));
          that.menuData = response.data.data;
          console.log("JSON.parse(response.data):::::" + response.data.data);
          //存储Menu信息
          that.$store.commit('changeMenuInfo', response.data.data);
        })
        .catch(function (error) {
        });

    },
    //点击导航,触发的页面跳转
    changePage(path) {
      //获取当前导航
      console.log("当前:" + this.$route.path);
      //关闭浮动菜单
      this.closeAlert();
      if (this.$route.path != path) {

        //跳转
        setTimeout(() => {
          router.push(path);
        }, 0)
      }
    },
    //关闭浮动菜单
    closeAlert() {
      //模拟点击关闭浮动菜单
      console.log("点击");
      this.$refs.offcanvas.click()
    }
    ,
    //初始化国家化
    initLanguage() {
      console.log("语言:" + localStorage.getItem('language'));
      if (localStorage.getItem('language') == "" || localStorage.getItem('language') == null) {
        localStorage.setItem('language', "en");
        location.reload();//刷新页面,使得语言生效
      }
      console.log("语言:" + localStorage.getItem('language'));


      this.$i18n.locale = localStorage.getItem('language');
      this.language = this.$i18n.locale;
    },
    //国家化,切换语言
    setLanguage() {
      // 切换语言
      this.language = this.$i18n.locale = this.language === 'zh' ? 'en' : 'zh';
      localStorage.setItem("language", this.language);
      // this.$router.go(0);
      location.reload();
    },
    logoAnimation() {
      //logo动画
      var that = this;
      gsap.from(".logoMobile", {
        //background:'white',
        filter: 'invert(100%)',
        duration: 4,
        repeat: 100,
        ease: 'power2.inOut', // 关键帧整体的动画曲线设置,平滑
        //delay:3,
        onStart() {
          console.log("阿萨德");
        }
      })

    },
  }
}
</script>
<style>
.icon,
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}


.colorBlack {
  color: black !important;
}

.colorWhite {
  color: rgba(255, 255, 255, 0.7) !important;
}




a.active.focus,
a.active:focus,
a.focus,
a:active.focus,
a:active:focus,
a:focus,
button.active.focus,
button.active:focus,
button.focus,
button:active.focus,
button:active:focus,
button:focus,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
    outline: 0;
    outline-color: transparent;
    outline-width: 0;
    outline-style: none;
    box-shadow: 0 0 0 0 rgba(0,123,255,0);
}
</style>