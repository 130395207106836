

export function initLanguage() {

    if (localStorage.getItem('language')) {
       // console.log('有记录'+localStorage.getItem('language'));
        //切换语言
      //  this.$i18n.locale = localStorage.getItem('language') === 'zh' ? 'en' : 'zh';
       // console.log(this.$i18n.locale);
    } else {
        console.log('无记录!!!');
        //设置本地默认语言

        //没有本地设置语言,则加载 前端语言默认配置(不做任何操作)(继续执行下面的程序!) 
        //没有本地设置语言,则加载 后端默认语言配置(后端更优先)
        this.$i18n.locale = 'en';
        localStorage.setItem("language", 'en');  
        //console.log("默认语言"+this.$i18n.locale);
    }
}