<template>
    <div class="row">
        <div ref="carouselExampleInterval" id="carouselExampleInterval" class="carousel slide carousel-fade" data-bs-ride="carousel"
            style="height:100vh;width:100%;position:absolute;top:0;z-index=-1;">

            <!-- 轮播图 开始 -->
            
            <!-- 轮播指标 开始 -->
            <div class="carousel-indicators" style="margin:0 auto;">
                <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="0" class="active"
                    aria-current="true" aria-label="Slide 1" :disabled="btnIsLock"></button>
                <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="1"
                    aria-label="Slide 2" :disabled="btnIsLock"></button>
                <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="2"
                    aria-label="Slide 3" :disabled="btnIsLock"></button>
                    <!-- 定位目标锚点位置,开始,请不要删除 -->
            <p ref="positionHref"></p>
            <!-- 定位目标锚点位置,结束,请不要删除 -->
            </div>
            <!-- 轮播指标 结束 -->
            <!-- 轮播内容 开始 -->
            <div class="carousel-inner" style="">
                <!-- data-bs-interval切换间隔 -->
                <div class="carousel-item active" data-bs-interval="5000"
                    style="height:100vh;width:100vw;text-align: center;">
                    <img style="height:100vh;object-fit:cover;object-position: center;"
                        :src="$store.state.scrollState.widthSB > $store.state.scrollWidthFixed.small.symbol?'http://gw-mall-bucket.oss-cn-beijing.aliyuncs.com/static/home/lunbo1_pc.jpg':'http://gw-mall-bucket.oss-cn-beijing.aliyuncs.com/static/home/lunbo1_mobile.jpg'"
                        class="d-block w-100 " alt="...">
                    <div class="carousel-caption d-md-block carsouleText" style="margin-bottom: 0px;">
                        <!-- <p style="font-family: Jungka,sans-serif;font-size: 24px;">Elegance, always.</p><br> -->
                        <p v-show="false"  style="font-family: Garamond,Baskerville,Caslon,serif;">{{ lang=='en' ? myText[0].en :myText[0].zh }}</p><br>
                        <p v-show="false">
                            <button type="button" class="btn btn-light btn1 btnFontSingle" style="background:rgba(255,255,255,0.3);font-family: Jungka,sans-serif;border-radius:29px !important;font-size: 12px;padding:20px;white-space: nowrap;width: fit-content;min-width: 20%;">{{ lang=="en"?"DISCOVER":"发现"}}</button>
                        </p><br>
                        <!-- 点击 锚点定位 开始 -->
                        <i class="icon icon-font icon-bg-down" style="font-size: 50px;color:rgba(255,255,255,1);" @click="toPositionHref()"></i>
                        <!-- 点击 锚点定位 结束 -->
                        
                    </div>
                </div>
                <!-- data-bs-interval切换间隔 -->
                <div class="carousel-item" data-bs-interval="5000">
                    <img style="height:100vh;width:100vw;object-fit:cover;object-position: center;"
                        :src="$store.state.scrollState.widthSB > $store.state.scrollWidthFixed.small.symbol?'http://gw-mall-bucket.oss-cn-beijing.aliyuncs.com/static/home/2_pc.jpg':'http://gw-mall-bucket.oss-cn-beijing.aliyuncs.com/static/home/2.jpg'"
                        
                        class="d-block w-100" alt="...">
                    <div class="carousel-caption d-md-block carsouleText">
                        <p style="font-family: Jungka,sans-serif;font-size: 24px;">{{ lang=='en' ? myText[1].en :myText[1].zh }}</p><br>
                        <p>
                            <!-- <button type="button" class="btn btn-light btn1 btnDouble" style="width:fit-content;font-family: Jungka,sans-serif;border-radius:29px !important;font-size: 12px;padding:20px;" @click="goodsListClick(17)">&nbsp;&nbsp;MAN&nbsp;&nbsp;</button>&nbsp;
                            <button type="button" class="btn btn-light btn1 btnDouble" style="width:fit-content;font-family: Jungka,sans-serif;border-radius:29px !important;font-size: 12px;padding:20px;" @click="goodsListClick(18)">WOMAN</button>
                             -->
                            <button type="button" class="btn btn-light btn1  btnFontSingle" style="font-family: Jungka,sans-serif;padding:20px !important;border-radius:29px !important;font-size: 12px;width: fit-content;min-width: 20%;" @click="goodsListClick(17)">{{ lang=="en"?"MAN":"男士" }}</button>
                       
                        </p><br>
                        <!-- 点击 锚点定位 开始 -->
                        <i class="icon icon-font icon-bg-down" style="font-size: 50px;" @click="toPositionHref()"></i>
                        <!-- 点击 锚点定位 结束 -->
                    </div>
                </div>
                <!-- data-bs-interval切换间隔 -->
                <div class="carousel-item" data-bs-interval="12000">
                    <!-- -->
                    <video src="../../static/videos/video1.mp4" autoplay muted loop playsinline @ended="ended()" 
                    style="height:100vh;width:100%;object-fit:cover;background: url('http://gw-mall-bucket.oss-cn-beijing.aliyuncs.com/static/home/lunbo3.jpg');background-size:cover !important;background-repeat: no-repeat !important;"></video>
                    <div class="carousel-caption d-md-block carsouleText">
                        <!-- <p style="font-family: Jungka,sans-serif;font-size: 24px;">"6 CIRO"</p><br> -->
                        <p style="font-family: Garamond,Baskerville,Caslon,serif;">{{ lang=='en' ? myText[2].en :myText[2].zh }}</p><br>
                        <p>
                            <button type="button" class="btn btn-light btn1  btnFontSingle" style="font-family: Jungka,sans-serif;padding:20px !important;border-radius:29px !important;font-size: 12px;width: fit-content;min-width: 20%;"> {{ lang=="en"? "DISCOVER":"发现"}}</button>
                        </p><br>
                        <!-- 点击 锚点定位 开始 -->
                        <i class="icon icon-font icon-bg-down" style="font-size: 50px;" @click="toPositionHref()"></i>
                        <!-- 点击 锚点定位 结束 -->
                    </div>
                </div>
            </div>
            
            <!--  -->
            <!-- 轮播内容 结束 -->
            <!-- 轮播切换按钮 开始 -->
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval"
                data-bs-slide="prev" :disabled="btnIsLock">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval"
                data-bs-slide="next" :disabled="btnIsLock">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
            <!-- 轮播切换按钮 结束 -->
            <!-- 轮播图 结束 -->
        </div>
        <br>
    </div>
</template>
<script>
import { gsap } from "gsap";

export default {
    data() {
        return {
            da: [
                {
                    type: "",//资源类型,1是图片,2是视频
                    srcMax: "",//大屏资源链接
                    srcMuni: "",//小屏资源链接
                    contentHtml: ""//描述文字,将解析成html格式
                }
            ],
            btnIsLock: true, // 控制按钮的disable属性
            myText:[
                {
                    en:"AruGaw  The stylist of men's wardrobe",
                    zh:"诺尜  男人的衣橱管家"
                },
                {
                    en:"AruGaw  The stylist of men's wardrobe",
                    zh:"诺尜  男人的衣橱管家"
                },
                {
                    en:"AruGaw  The stylist of men's wardrobe",
                    zh:"诺尜  男人的衣橱管家"
                }

            ]
        }
    },
    beforeDestroy(){
        //移除监听轮播
       // this.$refs.carouselExampleInterval.removeEventListener('slide.bs.carousel');
    },
    computed:{
        lang() {//语言
            return localStorage.getItem('language');
        }
    },
    mounted() {
        //轮播切换监听
        this.carouselChangeListener();



        //首屏动画
        gsap.from(
            ".carousel-item img", {
            scale: 8,
            opacity: 0,
            ease: 'power2.inOut', // 关键帧整体的动画曲线设置,平滑
            delay: 0,
            background:'rgba(0,0,0,0)',
            duration: 2,
            onStart: () => {
                console.log("start222");
            }
        }
        )

        var that = this;
        //文字进入
        gsap.from(
            ".carsouleText", {
            y: 100,
            opacity: 0,
            color: 'rgba(0,0,0,0)',
            ease: 'power2.inOut', // 关键帧整体的动画曲线设置,平滑
            duration: 1.5,
            delay: 1.5,
            onStart: () => {
                console.log("start222");
                that.clickBtn();
            }
        }
        )

    },
    methods: {
        goodsListClick(typeId) {
            console.log("当前:" + this.$route.path);
                //跳转
                this.$router.push({
                    name: 'goodsList',
                    query: {
                        'typeId': typeId,    //跳路由
                    }
                });

                //EventBus.$emit("IndexNavbarMsgGoodsList", typeId);
            
        },
        //切换时触发
        clickBtn(){
            var that = this;
            console.log("点击状态");
		 	// 设置按钮不可点击（disable: false）
             that.btnIsLock = true
			// 执行耗时间的代码或事件请求等....
			
			// 等待事件执行完或者相应的间隔时间后，恢复按钮为可点击状态
			setTimeout(() => {
				that.btnIsLock = false
			}, 2000)
		},
        toPositionHref() {
            this.$refs.positionHref.scrollIntoView();
        },

        click() {
            console.log("click");

        },
        //轮播上面元素动画
        carouselItemsAnnimation() {

        },
        //轮播切换事件监听,在mounted生命周期调用
        carouselChangeListener() {

            var that = this;
            that.$refs.carouselExampleInterval.addEventListener('slide.bs.carousel', function (event) {


                that.clickBtn();
                // var $hoder = $('#carouselExampleCaptions').find('.carousel-item'),
                //     $items = $(event.relatedTarget);
                // //getIndex就是轮播到当前位置的索引
                // var getIndex = $hoder.index($items);
                //this.carsouleTextAnnimation();
                //文字退出
                gsap.to(
                    ".carsouleText", {
                    y: 100,
                    opacity: 0,
                    color: 'rgba(0,0,0,0)',
                    ease: 'power2.inOut', // 关键帧整体的动画曲线设置,平滑
                    duration: 0.5,
                    onStart: () => {
                        console.log("start222");
                    }
                }
                )
                gsap.from(
                    ".carousel-item img", {
                    background: 'black',
                    scale: 1.1,
                    opacity: 0,
                    ease: 'power2.inOut', // 关键帧整体的动画曲线设置,平滑
                    delay: 0.5,
                    //background:'rgba(0,0,0,0)',
                    duration: 0.5,
                    onStart: () => {
                        console.log("start222");
                    }
                }
                )
                //文字进入
                gsap.from(
                    ".carsouleText", {
                    y: 100,
                    opacity: 0,
                    color: 'rgba(0,0,0,0)',
                    ease: 'power2.inOut', // 关键帧整体的动画曲线设置,平滑
                    duration: 0.5,
                    delay: 0.5,
                    onStart: () => {
                        console.log("start222");
                    }
                }
                )

                //




            })
        },
        // 视频播放结束监听
        ended() {
            console.log("播放完毕!!!");
        },
        //鼠标放到图标上动画
        iconMouseEnter() {
            console.log("mouseenter");
            gsap.from(
                ".icon-bg-down", {
                y: 100,
                background: 'red',
                duration: 1,
                onStart: () => {
                    console.log("start1111");
                }
            }
            )
        }
    }
}
</script>

<style scoped>

* {
    box-sizing: content-box;
    margin: 0;
    padding: 0;
}

.icon-bg-down:hover{
    cursor: pointer;
}

.btn1:hover{
    background-color: rgba(0,0,0,0);
    color:white;
}
/* 小屏 */
@media screen and (max-width: 768px) {
    .btnDouble{
        width:30% !important;
    }
}

/* 大屏 */
@media screen and (min-width: 768px) {
    .btnDouble{
        min-width:20% !important;
    }
}
</style>